import styled, { css } from 'styled-components';
import { color, container, screen } from '../../theme';

const roleVariants: {
  [key: string]: {
    backgroundColor: string | ((key: any) => any);
  };
} = {
  offer: {
    backgroundColor: color('sand_100')
  },
  offersList: {
    backgroundColor: color('white')
  },
  dark: {
    backgroundColor: color('midnight_900')
  },
  stackOverflow: {
    backgroundColor: '#111C27'
  },
  resistance: {
    backgroundColor: color('#111C27')
  },
  landing: {
    backgroundColor: color('white')
  },
  classicProfile: {
    backgroundColor: color('white')
  },
  light: {
    backgroundColor: color('white')
  },
  midnight_light: {
    backgroundColor: color('midnight_100')
  }
};

export const PageContentContainer = styled.div<{
  containerSize: 'md' | 'lg' | 'xl' | 'xlMax';
}>`
  max-width: ${({ containerSize }) => container(containerSize)};
  margin: auto;
`;

export const StyledPage = styled.div<{ variant?: string }>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;

  > header {
    margin-top: auto;
  }

  main {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    z-index: 1;
    background-color: ${color('sand_100')};

    ${({ variant }) =>
      variant &&
      roleVariants[variant] &&
      css`
        background-color: ${roleVariants[variant].backgroundColor};
      `}

    &.has-bottombar {
      @media (max-width: ${screen('lgMax')}) {
        > :nth-last-child(2) > *:first-child {
          padding-bottom: 4rem;
        }
      }
    }

    #pageTop {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }

  > footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }
`;
