import ReactMarkdown from 'react-markdown';
import { StyledMarkdown } from './MarkdownComponent.styled';

type MarkdownComponentProps = {
  content: string;
};

const ALLOWED_ELEMENTS = [
  'p',
  'strong',
  'ul',
  'ol',
  'li',
  'em',
  'a',
  'br',
  'img'
];

const MarkdownComponent: React.FC<MarkdownComponentProps> = ({ content }) => {
  return (
    <StyledMarkdown>
      <ReactMarkdown allowedElements={ALLOWED_ELEMENTS}>
        {content}
      </ReactMarkdown>
    </StyledMarkdown>
  );
};

export default MarkdownComponent;
