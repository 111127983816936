import styled from 'styled-components';
import {
  color,
  container,
  fontSize,
  screen,
  shadow,
  spacing,
  zindex
} from '../../../theme';
import { StyledTopbarNavItem } from './TopbarNav.styled';

export const StyledTopbar = styled.header<{ isTalent?: boolean }>`
  background-color: ${color('white')};
  height: ${spacing('xl4')};
  display: flex;
  align-items: center;
  transition: 0.3s;

  .headroom--unfixed &.is-transparent {
    background-color: transparent;
    box-shadow: unset;

    ${StyledTopbarNavItem} {
      color: ${color('text_default_inverse')};

      &:hover {
        color: ${color('link_inverse_hover')};
      }

      &:active,
      &:focus {
        color: ${color('link_inverse_active')};
      }

      &.active {
        color: ${color('active')};
      }
    }

    .brand {
      display: none;
    }

    .signin {
      color: ${color('text_default_inverse')};

      &:hover {
        color: ${color('link_inverse_hover')};
      }

      &:active,
      &:focus {
        color: ${color('link_inverse_active')};
      }
    }

    .signup {
      border-color: ${color('midnight_200')};
      color: ${color('midnight_200')};
      svg {
        color: ${color('midnight_200')};
      }

      &:hover,
      &:active,
      &:focus {
        border-color: ${color('midnight_500')};

        svg {
          color: ${color('midnight_500')};
        }
      }
    }

    .language-trigger {
      color: ${color('midnight_200')};
      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6' fill='none'%3E%3Cpath d='M0.538289 0.730988H7.46171C7.94067 0.730988 8.18015 1.30951 7.84111 1.64855L4.38075 5.11161C4.17087 5.32149 3.82913 5.32149 3.61925 5.11161L0.158886 1.64855C-0.180154 1.30951 0.0593268 0.730988 0.538289 0.730988Z' fill='%23dedce4'/%3E%3C/svg%3E");
      }

      &:hover,
      &:active,
      &:focus {
        background-color: ${color('midnight_850')};
      }
    }
  }

  .headroom--scrolled &.is-transparent {
    .brand--inverse {
      display: none;
    }
  }

  &:not(.is-transparent) {
    .brand--inverse {
      display: none;
    }
  }
`;

export const StyledTopbarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: ${container('xlMax')};
  padding-left: ${spacing('lg')};
  padding-right: ${spacing('md')};
  margin: auto;
  gap: 1rem;
`;

export const StyledTopbarNav = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const StyledTopbarNavTrigger = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  padding-top: 0.35rem;
  font-size: ${fontSize('xl')};
  color: ${color('midnight_300')};
  margin-right: ${spacing('md')};
  width: ${spacing('lg')};

  @media (min-width: ${screen('lg')}) {
    display: none;
  }
`;

export const StyledTopbarLogo = styled.a`
  img {
    height: 40px;
    display: block;

    @media (min-width: ${screen('lg')}) {
      margin-top: 0;
    }
  }
`;

export const StyledTopbarDesktopNav = styled.div`
  width: 100%;
  height: 100%;
  padding-left: ${spacing('sm')};
  margin-left: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: ${screen('lgMax')}) {
    display: none;
  }
`;

export const StyledTopbarMobileNav = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 0;
  overflow: hidden;
  background-color: ${color('white')};
  box-shadow: ${shadow('md')};
  width: 16.25rem;
  z-index: ${zindex('mobileNav')};
  transform: translateX(-16.25rem);
  transition: transform 0.3s;

  /* Forces Safari mobile to not take into account the navbar */
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }

  @media (min-width: ${screen('lg')}) {
    display: none;
  }

  &.is-active {
    transform: translateX(0);
  }
`;

export const StyledTopbarMobileNavContainer = styled.div`
  padding: ${spacing('lg')};
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledTopbarMobileNavHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${spacing('lg')};

  ${StyledTopbarLogo} {
    margin-top: -5px;
  }
`;

export const StyledTopbarNavClose = styled.button`
  order: -1;
  background: transparent;
  border: 0;
  padding: 0;
  font-size: ${fontSize('xl2')};
  color: ${color('midnight_300')};
  margin-right: ${spacing('md')};
  width: ${spacing('lg')};
`;

export const StyledTopbarMobileBottom = styled.div`
  margin-top: auto;
`;

export const StyledTopbarMobileUserActions = styled.div`
  background-color: ${color('midnight_100')};
  padding: ${spacing('lg')};
  margin: ${spacing('lg')} -${spacing('lg')} -${spacing('lg')};
  display: flex;
  align-items: center;
  gap: ${spacing('md')};
`;
