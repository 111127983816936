import {
  ButtonLink,
  Dropdown,
  IconLink,
  Stack,
  Tooltip
} from '@asgard/ui-library';
import { useUser } from '@auth0/nextjs-auth0/client';
import {
  faBuilding,
  faSignOut,
  faUserAstronaut
} from '@fortawesome/pro-solid-svg-icons';

import { useI18n } from 'hooks/useI18n';
import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import ServiceEventTracking from 'services/service.event-tracking';
import { APP_URLS, getAppUrl } from 'utils/app-urls';
import { config } from 'utils/config';
import { StyledHeaderActions } from './TopbarActions.styled';
import TopbarLanguage from './TopbarLanguage';

const TopbarActions: React.FC = () => {
  const { t } = useI18n('topbar');
  const { locale } = useRouter();
  const { user, isLoading } = useUser();

  if (isLoading) return null;

  return (
    <>
      <StyledHeaderActions>
        {!user && (
          <Stack gap="m">
            <ButtonLink
              href={`${getAppUrl(
                APP_URLS.join,
                locale
              )}?locale=${locale}&source=unete-header`}
              size="2xs"
              schema="brand2"
              variant="hard"
              label={t('actions.signup')}
              className="signup"
              onClick={(e) =>
                ServiceEventTracking.trackExternalLandingCtaClicked(
                  e,
                  `${getAppUrl(
                    APP_URLS.join,
                    locale
                  )}?locale=${locale}&source=unete-header`,
                  'unete-header'
                )
              }
              startIcon={faUserAstronaut}
            />

            <div className="signin">
              <Dropdown
                label={t('actions.signin')}
                variant="ghost"
                schema="brand2"
                size="xs"
                position="right"
                options={[
                  {
                    asLink: true,
                    href: `${getAppUrl(
                      APP_URLS.login,
                      locale
                    )}?locale=${locale}`,
                    id: 0,
                    label: 'Talento',
                    icon: faUserAstronaut
                  },

                  {
                    asLink: true,
                    href: `${config.talentEngineClientUrl}/external-login`,
                    id: 1,
                    label: 'Empresas',
                    icon: faBuilding
                  }
                ]}
              />
            </div>
          </Stack>
        )}

        {user && (
          <Stack gap="m">
            <Link href={getAppUrl(APP_URLS.portalV2Dashboard, locale)} passHref>
              <ButtonLink
                size="xs"
                schema="brand2"
                variant="hard"
                label={t('nav.privateArea')}
                startIcon={faUserAstronaut}
              />
            </Link>

            <Tooltip content={t('nav.logout')}>
              <Link href={getAppUrl(APP_URLS.logout, locale)} passHref>
                <IconLink
                  href="#"
                  size="xs"
                  schema="brand2"
                  variant="ghost"
                  icon={faSignOut}
                  aria-label={t('nav.logout')}
                />
              </Link>
            </Tooltip>
          </Stack>
        )}

        <TopbarLanguage />
      </StyledHeaderActions>
    </>
  );
};

export default TopbarActions;
